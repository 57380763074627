import React, {useState, useEffect, useCallback} from 'react';
import { _T } from '../../_i18n/i18n.helper';

import PullToRefresh from 'react-simple-pull-to-refresh';
import TransactionList from '../../components/TransactionList/TransactionsList';
import Footer from '../../components/Footer/Footer';

import { DEFAULT_CURRENCY } from "../../config";

import styles from './homePage.module.scss';
import fill8 from '../../assets/images/fill8.svg';
import transfert from '../../assets/images/transfert.svg';
import collect from '../../assets/images/collect.svg';
import more from '../../assets/images/more.svg';

import * as apiService from '../../_services/api.service';
import UIStore from "../../store/UIStore";
import useProvideAuth from "../../hooks/useProvideAuth";
import {useHistory} from "react-router-dom";

/**
 * Home Page
 */
function HomePage (props) {

  const history = useHistory();
  const [balance] = useState(272.33);
  const [items, setItems] = useState(null);
  const {user} = useProvideAuth();

  const getTransaction = useCallback(async () => {
    try {
      const transactions = await apiService.get(`users/${user.consumerId}/receipts`);
      setItems(transactions['items'].sort((a, b) => b.updatedAt - a.updatedAt));
    } catch(ex) {
      //
    }
  }, [user]);
  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    UIStore.setLoadingBarColor('#ffffff');
    getTransaction();
  }, [getTransaction]);


  const handleClick = (id) => {
    history.push(`/details/${id}`);
  };

  return (
    <PullToRefresh onRefresh={getTransaction}>
      <div className={styles.layout}>
        <div className={styles.header}>
          <img className={styles.fill8} src={fill8} alt={'fill'} />
          <div className={styles.title}>{_T('HOME_HEADER_TITLE')}</div>
          <div className={styles.balance}>{balance}{DEFAULT_CURRENCY}</div>
          <div className={styles.balanceTitle}>{_T('HOME_BALANCE_TITLE')}</div>
        </div>

        <div className={styles.main}>
          <div className={styles.buttonsBar}>
            <button><img src={transfert} alt={'transfert'} /><span>{_T('HOME_TRANSFERT')}</span></button>
            <button><img src={collect} alt={'collect'} /><span>{_T('HOME_COLLECT')}</span></button>
            <button><img src={more} alt={'more'} /></button>
          </div>

          <div className={styles.view}>
            <div className={styles.viewBar}>
              <span className={styles.mainTitle}>{_T('HOME_ACTIVITY')}</span>
              <button className={styles.seeAllBtn}>{_T('HOME_SEE_ALL')}</button>
            </div>

            {items && <TransactionList items={items} handleClick={handleClick} />}
          </div>
        </div>

        <Footer {...props} />
      </div>
    </PullToRefresh>
  )
}

export default HomePage;