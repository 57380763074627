import React from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { _T } from '../../_i18n/i18n.helper';
import { DEFAULT_CURRENCY } from "../../config";

import styles from './transactionList.module.scss';
import ticket from '../../assets/images/ticket.svg';

/**
 * Home Page
 */
function total (ticket) {
    //let _totalTTC = 0;
    /*(ticket['items'] || []).forEach((item) => {
        _totalTTC += item.amount * Number(item.quantity);
    });*/
    return (+(Math.round(ticket.amount + "e+" + 1)  + "e-" + 1));
}
const TransactionList = observer(
  function TransactionList (props) {

    const getDate = (date)  => {
      const dateFormat = moment(date,'x');
      const now = moment();
      let diff = now.diff(dateFormat, 'minutes');
      if(diff < 60) return `${_T('THERE_IS')} ${diff} ${_T('MINUTES')}`;

      diff = now.diff(dateFormat, 'hours');
      if(diff < 24) return `${_T('THERE_IS')} ${diff} ${_T('HOURS')}`;

      return dateFormat.format(`[${_T('THE')}] DD/MM [${_T('AT')}] HH[h]mm`);
    };

    return (
      <div className={styles.list}>
        {
          props.items.map((item, i) => (
            <div key={i} className={styles.item} onClick={() => props.handleClick(item.id)}>
              <div className={styles.col}>
                <i className={styles.icon}>{(item.storeName || '').substr(0,1).toUpperCase()}</i>
              </div>

              <div className={styles.label}>
                  {!!item.merchantName ? item.merchantName : item.storeName}
                <div className={styles.date}>{getDate(item.createdAt)}</div>
              </div>

              <div className={styles.col}>
                {item.amount > 0 && <img className={styles.ticket} src={ticket} alt={'ticket'} /> }
              </div>

              <div className={styles.col}>
                <span className={styles.price}>-{total(item)}{DEFAULT_CURRENCY}</span>
              </div>
            </div>
          ))
        }
      </div>
    )
  }
);

export default TransactionList;