import React from 'react';
import { observer } from 'mobx-react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import PWAPrompt from 'react-ios-pwa-prompt'
import LoadingBar from 'react-top-loading-bar';
import UIStore from './store/UIStore';
import * as i18nService from './_i18n/i18n.service';
import { _T } from './_i18n/i18n.helper';
import DetailsPage from "./pages/Details/DetailsPage";
import HomePage from "./pages/Home/HomePage";
import LoginPage from "./pages/Auth/LoginPage";
import ProvideAuth from "./components/ProvideAuth";
import PrivateRoute from "./components/PrivateRoute";

i18nService.translate();

const App = observer(
  class App extends React.Component {

    componentDidMount() {
      i18nService.translate();
    }

    render() {
      return (
        UIStore.appReady &&
          <ProvideAuth>
            <>
              <PWAPrompt copyTitle={_T('PWA_ADD_TO_SCREEN')}
                         copyBody={_T('PWA_BODY')}
                         copyShareButtonLabel={_T('PWA_SHARE_BTN')}
                         copyAddHomeButtonLabel={_T('PWA_HOME_BTN')}
                         copyClosePrompt={_T('PWA_CLOSE')}
              />
              <div style={{position: 'fixed', top: 0, left: 0, right: 0, height: 3, zIndex: 9999}}>
                <LoadingBar
                  height={3}
                  color={UIStore.loadingBarColor}
                  ref={ref => (UIStore.setLoadingBar(ref))}
                />
              </div>

              <Router>
                <Switch>
                  <PrivateRoute exact path='/'>
                    <HomePage />
                  </PrivateRoute>
                  <PrivateRoute exact path='/details/:id'>
                    <DetailsPage />
                  </PrivateRoute>
                  <Route path='/login'>
                    <LoginPage />
                  </Route>
                  <Redirect path='*' to='/'/>
                </Switch>
              </Router>
            </>
          </ProvideAuth>
      );
    }
});

export default App;
