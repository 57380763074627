import ax from 'axios';
import { API_URL } from '../config';
import UIStore from "../store/UIStore";

export async function request (method, endpoint, data, options) {
  try {
    let args = [`${API_URL}/${endpoint}`];
    if(data) args.push(data);
    if(options) args.push(options);

    const func = ax[method];

    // noinspection JSUnresolvedFunction
    UIStore.loadingBar.continuousStart();
    const response = await func(...args);
    // noinspection JSUnresolvedFunction
    UIStore.loadingBar.complete();

    if(options && options.responseType === 'blob') return response;
    else return response['data'];

  } catch (error) {

  // noinspection JSUnresolvedFunction
    UIStore.loadingBar.complete();

    switch (error.response.status) {
      case 403:
        break;
      case 404:
        break;
      case 401:
      case 498:
        break;
      default:
        break;
    }

    return Promise.reject(error);

  }

}

export async function get (endpoint, options, disableToast) {
  return await request('get', endpoint, options, disableToast);
}
export async function post (endpoint, data, options, disableToast) {
  return await request('post', endpoint, data, options, disableToast);
}
export async function put (endpoint, data, options, disableToast) {
  return await request('put', endpoint, data, options, disableToast);
}
export async function patch (endpoint, data, options, disableToast) {
  return await request('patch', endpoint, data, options, disableToast);
}
export async function del (endpoint, options, disableToast) {
  return await request('delete', endpoint, options, disableToast);
}
