const auth = {
    isAuthenticated: false,
    async signin({username, password}) {
        return new Promise((resolve) => {
            setTimeout(() => {
                auth.isAuthenticated = true;
                auth.user = {consumerId: username, username};
                resolve(auth.user);
            }, 100);
        })
    },
    async signout() {
        auth.isAuthenticated = false;
        auth.user = undefined;
    }
};

export default auth