import {useState} from 'react';
import authService from '../_services/auth.service';

const LOCAL_STORAGE_KEY_USER = 'kb_demo_bank_user';

function getLocalStorageUser() {
    const v = localStorage.getItem(LOCAL_STORAGE_KEY_USER);
    return v ? JSON.parse(v) : undefined;
}
function setLocalStorageUser(user) {
    if (user) {
        localStorage.setItem(LOCAL_STORAGE_KEY_USER, JSON.stringify(user));
    } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY_USER);
    }
    return user;
}
function useProvideAuth() {
    const [user, setUser] = useState(getLocalStorageUser);

    const signin = async ({username, password}) => {
        const user = await authService.signin({username, password});
        setLocalStorageUser(user);
        setUser(user);
    };

    const signout = async () => {
        await authService.signout();
        setLocalStorageUser(undefined);
        setUser(undefined);
    };

    return {
        user,
        signin,
        signout
    };
}

export default useProvideAuth