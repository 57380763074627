import React, {useEffect, useState, useCallback} from 'react';
import { _T } from '../../_i18n/i18n.helper';
import { API_URL } from '../../config';
import Footer from '../../components/Footer/Footer';
import styles from "./detailsPage.module.scss";
import back from '../../assets/images/back.svg'
import search from '../../assets/images/search.svg'
import * as apiService from "../../_services/api.service";
import UIStore from "../../store/UIStore";
import {useHistory, useParams} from "react-router-dom";
/**
 * Details Page
 */
function DetailsPage (props) {

  const history = useHistory();
  const params = useParams();
  const [ticket, setTicket] = useState(null);

  const getTicket = useCallback(async () => {
    try {
      const { id } = params;
      const ticket = await apiService.get(`receipts/${id}`);
      setTicket(ticket);
    } catch(ex) {
    }
  }, [params, setTicket]);

  useEffect(() => {
    // You need to restrict it at some point
    // This is just dummy code and should be replaced by actual
    UIStore.setLoadingBarColor();
    getTicket().then(() => {})
  }, [getTicket]);
  return (
    <div className={styles.layout}>
      <div className={styles.header}>
        <button><img src={back} onClick={() => history.push(`/`)} alt={'back'} /></button>
        <div>{_T('DETAILS_TITLE')}</div>
        <button><img src={search} alt={'search'} /></button>
      </div>
      {ticket && <div className={styles.ticket}>
        <img alt="tutu" src={ `${API_URL}/faketicket/${ticket.id}`}/>
      </div> }
      <Footer />
    </div>
  )
}

export default DetailsPage;