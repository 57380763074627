import React from "react";
import "./styles.css";
import { useForm } from "react-hook-form";

export function LoginForm({onSubmit}) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <input placeholder='Votre Nom' {...register("username", { required: true })}/>
            {errors.username && <span style={{color:"red"}}>This field is required</span>}
            <input placeholder="Votre Mot de passe" {...register("password", { required: true })} style={{marginTop: "50px"}} />
            {errors.password && <span style={{color:"red"}}>This field is required</span>}
            <input type="submit" className="form__custom-button" value="ENTRER" />
        </form>
    );
}

export default LoginForm
