import React from 'react';
import useAuth from "../../hooks/useAuth";
import {useHistory,useLocation} from "react-router-dom";
import LoginForm from "../../components/LoginForm";
import logoKillBills from "../../assets/images/logo-menu.png"

function LoginPage () {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();
  let { from } = location.state || { from: { pathname: "/" } };
  let onLogin = ({username, password}) => {
    auth.signin({username, password}).then(() => {
      history.replace(from);
    });
  };
  return (
      <>
          <div style={{textAlign:"center"}}>
              <img src={logoKillBills} alt='KillBills logo' style={{maxWidth:"100%", height:"auto", paddingTop:"25px"}} />
          </div>
          <LoginForm onSubmit={onLogin} />
      </>
  )
}

export default LoginPage;