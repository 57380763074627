import React, {useCallback} from 'react';
import { observer } from 'mobx-react';
import { _T } from '../../_i18n/i18n.helper';
import useAuth from "../../hooks/useAuth";
import {useHistory} from "react-router-dom";

import styles from './footer.module.scss';

import home from "../../assets/images/home.svg";
import account from "../../assets/images/account.svg";
import folder from "../../assets/images/folder.svg";
import settings from "../../assets/images/settings.svg";

const TransactionList = observer(
  function TransactionList () {
      let history = useHistory();
      let auth = useAuth();
      const onLogout = useCallback(() => {
          auth.signout().then(() => history.push("/"));
      }, [auth, history])
    return (
      <div className={styles.footer}>
        <button onClick={() => history.push(`/`)}><img src={home} alt={'home'} /><span>{_T('HOME_HOME')}</span></button>
        <button><img src={account} alt={'account'} onClick={onLogout} /></button>
        <button><img src={folder} alt={'folder'} /></button>
        <button><img src={settings} alt={'settings'} /></button>
      </div>
    )
  }
);

export default TransactionList;