import { makeObservable, observable, action } from 'mobx';
import { DEFAULT_LANG, LOADINGBAR_COLOR } from '../config'

class UIStore {

  appReady = false;
  lang = false;
  loadingBar = false;
  loadingBarColor = LOADINGBAR_COLOR;

  constructor() {
    makeObservable(this, {
      appReady: observable,
      lang: observable,
      loadingBar: observable,
      appIsReady: action,
      setLanguage: action,
      setLoadingBar: action,
      loadingBarColor: observable,
      setLoadingBarColor: action,
    });

    this.lang = DEFAULT_LANG;
  }

  appIsReady() {
    this.appReady = true;
  }

  setLanguage(lang) {
    this.lang = lang;
  }

  setLoadingBar(ref) {
    this.loadingBar = ref;
  }

  setLoadingBarColor(color) {
    this.loadingBarColor = color || LOADINGBAR_COLOR;
  }
}

export default new UIStore();