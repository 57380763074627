import intl from 'react-intl-universal';
import moment from 'moment';
import 'moment/locale/fr';
import UIStore from "../store/UIStore";

import localefr from "./locales/fr-FR.json";

/**
 * Load the lang locale forms the backend
 * @param lang
 * @returns {Promise<*>}
 */
export async function loadLocale(lang) {
  try {
    const response = localefr; //await ax.get(`${API_URL}/locale/${lang}`);
    return response;

  } catch (error) {
    console.error(error);
    return false;

  }

}

/**
 * Load the "lang" locale and refresh App
 * @param lang
 * @returns {Promise<void>}
 */
export async function translate(lang) {

  if(!lang) {
    lang =
      UIStore.lang
      || (navigator.languages && navigator.languages[0])
      || navigator.language
      || navigator.userLanguage
      || 'fr-FR';
  }

  const locales = {};

  locales[lang] = await loadLocale(lang);

  intl.init({
    currentLocale: lang,
    locales,
  }).then(() => {
    lang = lang.split('-')[0];
    UIStore.setLanguage(lang);
    moment.locale('fr');

    UIStore.appIsReady();
  });

}